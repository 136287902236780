footer {
  background-color: #1b1717;
  padding-bottom: 3rem;
  color: #FCFCFC;
}
footer .footerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem 0;
}
.footerBox {
}
.footerBox .logo{
  width: 20rem;
}
.footerBox a {
  font-size: 20px;
  font-weight: 600;
  color: #FCFCFC;
  text-decoration: none;
}
.boxItems {
  padding: 0;
  font-size: 15px;
}
.boxItems a {
  padding: 0;
  font-size: 15px;
}
.boxItems i {
  font-size: 30px;
  vertical-align: middle;
  padding-right: 2rem;
}
.boxItems.paragraphItem {
  padding-top: 3rem;
}
.boxItems .iconItems {
  padding-top: 1rem;
}
.boxItems .iconItems:hover {
  cursor: pointer;
}
.locationItem, .emailItem, .inputItem, .buttonItems {
  padding-top: 1rem;
}
.boxItems input {
  background: rgba(255, 255, 255, 0.25);
  border: none;
  border-bottom: 3px solid #757575;
  height: 4rem;
  width: 23rem;
  font-size: 15px;
}
.boxItems input:focus {
  outline: none;
  color: #fff;
}

.legal {
  text-align: center;
  padding: 2rem 5rem 0;
  background: #1b1717;
  color: #FCFCFC;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  cursor: default;
}

.legalList {
  display:inline-block;
  justify-content: space-between;
}
.legalList li {
  font-size: 12px;
  align-items: flex-end;
}
.legalFirstItem {
  margin-right: auto;
  /* align-items: flex-start; */
}
.legalItem  a {
  padding-left: 7%;
  cursor: pointer;
  color: #FCFCFC;
  text-decoration: none;
}

.breakLine {
  width: 90%;
  height: 1px;
  border: none;
  border-radius: 50px;
  justify-content: center;
  background-color: rgb(107, 106, 106);
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  footer {
    /* overflow-x: hidden; */
  }
  footer .footerContainer {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .footerBox a {
    font-size: 17px;
  }
  .footerBox, .legal {
    padding: 1rem;
  }
  .legal li {
    width: 100%;
  }
  .legalList {
    display:inline-block;
  }
  .legalList li {
    font-size: 12px;
  }
  .legalItem {
    padding-left: 0;
  }
}