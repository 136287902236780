.imageSlideContainer {
  padding: 3rem;
}
.slideContainer {
  direction: ltr;
  width: 100%;
  margin: auto;
  padding: 0;
}
.eachSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vw;
}
.react-slideshow-container .nav[data-type="prev"] span {
  margin-left: 5px;
  border-right: none;
  border-top: none;
}
.react-slideshow-container .nav[data-type="next"] span {
  margin-right: 5px;
  border-left: none;
  border-bottom: none;
}
.eachSlide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.each-fade {
  display: flex;
}
.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}
.each-fade .image-container img {
  width: 100%;
}
.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}
.react-slideshow-container + div.indicators > div:last-of-type {
  margin-right: 10px;
}

@media screen and (max-width: 800px) {
  .imageSlideContainer {
    padding: 1rem;
  }
  .eachSlide {
    height: 100%;
  }
  .eachSlide > div {
    height: 200px;
  }
}