.headerTransparent {
  height: 85px;
  width: 100%;
  padding: 0 7%;
  display: flex;
  align-items: center;
  z-index: 10;
  font-weight: 600;
  background-color: transparent;
  position: fixed;
  transition: 1s;
}
.headerTransparent.active {
  background: #fff;
}
.logoContainer {
  display: block;
  width: auto;
  height: auto;
}
.logoContainerMobile {
  display: none;
}
.logo {
  /* min-width: 15rem; */
  max-height: 7rem;
  vertical-align: middle;
  object-fit: contain;
}
.navMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
nav ul li {
  list-style: none;
  display: flex;
  position: relative;
  align-items: center;
}
nav ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  transition: 1s;
}
.headerTransparent.active ul li a {
  color: #404040;
}
nav ul li i {
  font-size: 25px;
  vertical-align: middle;
}
.toggleButton {
  display: none;
}

.projectsDropdown {
  display: block;
  position: absolute;
  width: 20rem;
  height: fit-content;
  color: #fff;
  font-size: 17px;
  background-color: rgba(75,75,75, 0.5);
}
.projectsDropdown li {
  width: 100%;
  padding: 1rem 0 1rem 0;
  margin: 0;
  text-align: center;
}
.projectsDropdown li:hover {
  background-color: #4d4d4d;
}

@media screen and (max-width: 800px) {
  .headerTransparent {
    position: fixed;
  }
  .logoContainerMobile {
    display: flex;
  }
  .toggleButton {
    cursor: pointer;
    color: #000;
    background: none;
    border: none;
    display: block;
    position: fixed;
    left: 25px;
    top: 25px;
    z-index: 1000;
    /* width: fit-content; */
  }
  .toggleButton i {
    font-size: 3rem;
  }
  .navMenu {
    display: none;
  }
  .mobilePosition {
    left: 85%;
  }

  .mobileNavMenu::before {
    display: block;
    height: 80px;
    content: "\00a0";
  }
  .mobileNavMenu li {
    width: 100%;
  }
  .headerTransparent {
    justify-content: center;
  }
  nav .logo {
    height: 6rem;
  }
  .headerTransparent.active ul li a {
    color: #fff;
  }

  .mobileHeader {
    position: absolute;
    height: 100vh;
    width: 80vw;
    background-color: #000;
    z-index: 10;
    top: 0;
    left: -80vw;
    animation: mobileHeader 0.5s forwards;
    -webkit-animation: mobileHeader 0.5s forwards;
  }
  @keyframes mobileHeader {
    from { transform: translateX(0%); }
    to { transform: translateX(100%); }
  }
  .mobileNavMenu {
    padding-left: 3rem;
  }
  .mobileNavMenu li {
    padding-bottom: 1rem;
  }
}
