@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 10px;
  /* overflow-x: hidden; */
  /* outline: 1px solid #f00 !important;
  background: #000 !important;
  color: #fff !important; */
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
