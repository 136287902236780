.gallery {
  padding: 2rem 8rem 6rem 8rem;
}

.gallery h4 {
  font-size: 35px;
  color: #1B1717;
  padding: 2rem 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.07em;
}

@media screen and (max-width: 800px) {
  .gallery {
    padding: 2rem 4rem 6rem 4rem;
  }
  .gallery h4 {
    font-size: 2.5rem;
  }
}