.hero {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(58, 60, 64, 0.56), rgba(58, 60, 64, 0.56));
  position: relative;
  display: flex;
  justify-content: left;
  overflow-x: hidden;
}

.content {
  width: 100%;
  text-align: center;
  padding-top: 40vh;
  cursor: default;
}
.content h1 {
  font-size: 70px;
  font-family: Monoton;
  color: #fff;
  font-weight: 400;
  transition: 0.5s;
}
.content h4 {
  font-size:40px;
  color: #D7D7D7;
  font-weight: 400;
  font-family: 'Rouge Script', cursive;
}
.backVideo {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  /* .backVideo {
    width: auto;
    height: 100%;
  } */
  .content {

  }
  .content h1 {
    font-size: 35px;
  } 
}