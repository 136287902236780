.container {
  display: flex;
  padding: 6rem 8rem 0 8rem;
  justify-content: space-between;
  align-items: center;
  /* height: 30rem; */
}

.imageBlock {
  /* height: 20rem; */
  width: 100%;
  display: flex;
  justify-content: center;
}
.imageBlockRight {
  margin-left: 5rem;
}
.imageBlockLeft {
  margin-right: 5rem;
}

.imageBlock img {
  border-radius: 40px;
  object-fit: cover; /* or contain to show the whole */
  height: 30rem;
}
.imageBlockRight img {
  height: 40rem;
  width: 35rem;
}

.textBlock {
  padding: 1rem 2rem 1rem 2rem;
  width: 100%;
}
.textBlock h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #1b1717;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}
.textBlock .textDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6e6e6e;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    justify-content: center;
    padding: 3rem 3rem 0 3rem;
  }
  .textBlock {
    padding: 1rem 0rem;
  }
  .textBlock h1{
    font-size: 2.5rem;
  }

  .imageBlockRight {
    margin-left: 0;
  }
  .imageBlockLeft {
    margin-right: 0;
  }

  .imageBlock img {
    height: 60vw;
    width: 100%;
  }
}
