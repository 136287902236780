.coverImage {
  padding: 8rem 8rem 0 8rem;
  width: 100%;
  height: 40vw;
}
.coverImage img {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.subBlockOne {
  display: flex;
  padding: 8rem;
  justify-content: space-between;
}
.infoContainer {
  width: 50%;
  padding-right: 5rem;
}
.infoContainer h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.07em;
  color: #404040;
}
.infoContainer h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #000000;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.infoContainer div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #000000;
}

.formContainer {
  width: 50%;
}
.formContainer h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.07em;
  color: #404040;
}

@media screen and (max-width: 800px) {
  .coverImage {
    padding: 3rem 3rem 0 3rem;
  }

  .subBlockOne {
    flex-direction: column;
    justify-content: center;
    padding: 3rem 3rem 3rem 3rem;
  }

  .infoContainer {
    width: 100%;
  }
  .infoContainer h1 {
    font-size: 25px;
  }
  .infoContainer h4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 20px;
  }
  .infoContainer div {
    font-size: 18px;
  }
  .formContainer {
    width: 100%;
  }
  .formContainer h1 {
    font-size: 25px;
  }
}
