.disclaimer {
  padding: 10rem 6rem 1rem 6rem;
}

.disclaimer .title {
  text-align: center;
  font-size: 35px;
  line-height: 20px;
}

@media screen and (max-width: 800px) {
  .disclaimer {
    padding: 10rem 3rem 1rem 3rem;
  }
  .disclaimer .title {
    text-align: center;
    font-size: 30px;
    line-height: 20px;
  }
}