.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #1b1717;
  /* background-position: center;  */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

.titleBox {
  background-color: rgba(74, 53, 38, 0.6);
  backdrop-filter: blur(7.5px);
  height: 20rem;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: "Open Sans";
  padding: 0 7rem;
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .heading {
    /* flex-direction: column; */
    /* justify-content: center; */
    /* padding: 7rem 3rem 3rem 3rem; */
    height: 50vh;
  }
  .title {
    /* text-align: center; */
    /* overflow: hidden; */
    font-size: 2.5rem;
    padding: 0 5px;
  }
  .titleBox {
    width: 100vw;
    height: 10rem;
  }
}
