.galleryChild {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 7vw);
  grid-gap: 15px;
  /* background-color: #000; */
  position: relative;
  padding: 10px 0;
}
.parallelGalleryChild {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 33vw);
}

.galleryChild img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.galleryItem1Left {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
}
.parallelGalleryChild .galleryItem1Left {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

.galleryItem2Left {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
}
.parallelGalleryChild .galleryItem2Left {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}

.galleryItem3Left {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 5;
}
.parallelGalleryChild .galleryItem3Left {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.galleryItem1Right {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.galleryItem2Right {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
}
.galleryItem3Right {
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 5;
}
.imageContainer {
  position: relative;
}
.imageCover {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  border-radius: 10px;
  transition: opacity 0.5s;
  cursor: pointer;
}
.imageCover:hover {
  opacity: 0.5;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 5rem 10rem;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 20;
  vertical-align: middle;
}
.modalContainer i {
  cursor: pointer;
  position: absolute;
  right: 7rem;
  top: 5rem;
  color: #fff;
  font-size: 50px;
  z-index: 25;
  align-items: right;
}

.modalContainer img {
  object-fit: contain;
}

@media screen and (max-width: 800px) {
  .modalContainer {
    padding: 0;
    height: 100%;
    text-align: center;
  }
  .modalContainer .slideContainer {
    position: relative;
    top: 20%;
  }
  .modalContainer i {
    right: 2rem;
    top: 2rem;
  }
  .imageCover:hover {
    opacity: 0;
  }
}
