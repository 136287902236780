.blocksContainer {
  padding: 6rem 6rem 6rem 6rem;
}
.blocksContainer h1 {
  font-size: 2.5rem;
  padding-top: 2rem;
  display: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0.07em;
}

ul.listContainer {
  list-style-type: none;
  display: flex;
  color: #989797;
}

.listItem {
  width: 25%;
  height: 8rem;
  border-radius: 5px;
  align-items:center;
  text-align: center;
  display: flex;
  margin-left: 2rem;
  cursor: default;
}
.listItem img {
  height: 60px;
}
.listItem i {
  font-size: 55px;
  vertical-align: middle;
}
.listItem div {
  font-size: 20px;
  width: 100%;
  font-weight: 700;
}

@media screen and (max-width: 800px) {
  .blocksContainer {
    padding: 1rem 3rem 3rem 3rem;
  }
  .blocksContainer h1 {
    display: block;
  }
  ul.listContainer {
    display: block;
  }
  li.listItem {
    width: 90%;
    height: 15rem;
    margin: 0 auto;
    padding-bottom: 0;
    padding: 1rem;
  }
  .arrowButton {
    position: relative;
    bottom: -10%;
  }
  .listItem div {
    /* justify-content: center; */
    padding: 5px 1.5rem 5px 1.5rem;
    font-size: 15px;
  }
  .listItem svg {
    height: 60px;
    width: 60px;
  }
}
