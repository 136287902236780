.container {
  position: relative;
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.container header {
  font-size: 1.5rem;
  color: #333;
  font-weight: 500;
  text-align: center;
}
.container .form {
  margin-top: 30px;
}
.form .inputBox {
  width: 100%;
  margin-top: 20px;
}
.inputBox label {
  color: #000;
  font-size: 20px;
}
.inputBox .inputLabel {
  color: red;
}
.form :where(.inputBox input) {
  position: relative;
  height: 50px;
  width: 100%;
  outline: none;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0 15px;
}
.form :where(.textArea) {
  position: relative;
  height: 16rem;
  width: 100%;
  outline: none;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  resize: none;
}
.inputBox input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.textArea:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.form .column {
  display: flex;
  column-gap: 15px;
}
.gender-box h3 {
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}
.form :where(.gender-option, .gender) {
  display: flex;
  align-items: center;
  column-gap: 50px;
  flex-wrap: wrap;
}
.select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  color: #707070;
  font-size: 1rem;
}
.form button {
  height: 50px;
  width: 18%;
  min-width: 100px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgb(46, 57, 64);
  border-radius: 5px;
  float: right;
}
.form button:hover {
  background: rgb(32, 41, 46);
}
/*Responsive*/
@media screen and (max-width: 500px) {
  .form .column {
    flex-wrap: wrap;
  }
  .form :where(.gender-option, .gender) {
    row-gap: 15px;
  }

  .inputBox label {
    color: #000;
    font-size: 18px;
  }
}
